import { Vue, Component } from 'vue-property-decorator';
const Content = () => import(`@/assets/docs/${'terms'}.md`);

@Component({
  head() {
    return {
      title: 'TERMS OF SERVICE',
    };
  },

  components: {
    Content,
  },
})
export default class Terms extends Vue {

}
